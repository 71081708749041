
<template>
  <div class="Notification">
    <div class="content">
      <div class="icon">
      <svg viewBox="0 0 52 52">
  <circle cx="26" cy="26" r="25" fill="none"/>
  <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
</svg>
</div>

     <div class="text">  {{ $store.state.currentNotification.text }}</div>
 
    </div>
  </div>
</template>

<script>
import { animate, glide, spring, timeline, stagger } from "motion"

export default {
  name: 'Notification',
  mounted() {
    let self = this;
    this.$nextTick(() => {
      const sequence = [
        [".Notification", { y: [0, 100] }, { easing: glide({ velocity: -250, decay: 0.15 }) }],
        [".Notification", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
      ]

      var animation = timeline(sequence, {});
      console.log(animation)

    })

  setTimeout(() => {
      console.log('HIDE ')
      let fadeout = [
        [".Notification", { opacity: 0 }, { easing: "ease-in-out" }],
      ]
      timeline(fadeout, {}).finished.then(() => {
        self.$store.commit('toggleNotification', { text: 'Updated', toggle: false })
      })
    }, 2000)  

  },

  data() {
    return {
    }
  },
  watch: {
    '$store.state.currentNotification.toggle': function () {
      let self = this;
      console.log(this.$store.state.currentNotification)
      /*      const sequence = [
             [".Notification", { y: [0, 100] }, {}],
             [".Notification", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
           ]
     
           var animation = timeline(sequence, {});
     
           setTimeout(() => {
             console.log('HIDE ')
             self.$store.commit('toggleNotification', { text: 'Updated', toggle: false })
           }, 5000) */

    }
  },
  props: {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.Notification {
  opacity: 0;
  color: var(--gray-600);
    z-index: 999999999;
    background: white;
   // height: 72px;
   width: auto;
    display: flex;
    position: absolute;
    bottom: -216px;
    right: 16px;
   // margin: auto;
   padding: 16px;
    box-shadow: -1px 11px 70px -30px var(--gray-400);
    box-shadow: -1px 4px 40px -12px var(--gray-300);
    border-radius: 22px;
   
  /* backdrop-filter: blur(21px); */
}

.Notification .content {
    display: flex;
    align-items: center;
  gap: 12px; 
    font-size: 15px;
    font-weight: 500;
    color: var(--gray-400);
    justify-content: space-between;
    width: -webkit-fill-available;
    flex-direction: row-reverse;
}

.icon {
    display: flex;
    --icon-color:#5589ff;
}
svg {

  border-radius:100%;
  box-shadow: inset 0px 0px 0px var(--icon-color);
  animation: fill .4s ease .2s forwards, scale .3s ease .5s both;

  height: 28px;
    width: 28px;
}

circle {
  stroke-dasharray: 165;
  stroke-dashoffset: 165;
  stroke-width: 2;
  stroke: var(--icon-color);
  animation: stroke .7s ease forwards;
}

path {
  stroke-width: 4;
  stroke: #fff;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s ease .5s forwards;
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 35px var(--icon-color);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
     transform: scale(1.3);
  }
}  

</style>

